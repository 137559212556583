.root {
  padding: 20px 0;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.headerText {
  margin: 0 20px 0 0 !important;
}

.classifiersList {
  align-items: flex-start;
}

.classifierRow {
  margin-bottom: 10px;
}

.classifierHeader {
  padding: 20px;
}

.classifierName {
  font-weight: 600;
  margin-right: 10px;
}

.classifierActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optionsHeader {
  margin: 0 auto !important;
  cursor: pointer;
  display: table;
}

.option {
  padding-left: 20px;
}

.formWrapper {
  padding: 20px !important;
}