.root {
  padding-bottom: 20px;
}

.fieldWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.removeButtonWrapper {
  margin: 0 20px;
}

.optionsWrapper {
  padding: 0 40px;
}