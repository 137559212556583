.root {
  display: flex;
  height: calc(100vh - 123px);
}

.inputsWrapper {
  width: 30%;
  height: 100%;
  padding-right: 20px;
}

.photoWrapper {
  background: rgba(34, 36, 38, 0.03);
  width: 70%;
  height: 100%;
  position: relative;
}
