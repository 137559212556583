.root {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formWrapper {
  min-width: 500px;
}
