@import "../../styles/fonts";

.root {
  display: flex;
  background: rgb(48, 48, 48);
  color: #fff;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 200px;
  padding: 20px;
}

.signOutBtn {
  cursor: pointer;
  padding: 20px;
}

.menu {
  position: absolute;
  top: 76px;
  bottom: 0;
  right: 0;
  padding: 40px 100px 40px 40px;
  background: white;
  border-left: 1px solid black;
  z-index: 10;
}

.link {
  color: black;
  cursor: pointer;
  display: block;
  @include paragraph;
  padding: 10px 0;
  margin: 10px 0;
  font-weight: 600;
  user-select: none;

  &:hover {
    color: #4a90e2;
    text-decoration: underline;
  }
}

.active {
  color: #4a90e2;
}
