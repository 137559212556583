.root {
  position: relative;
  padding: 20px 0;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.headerText {
  margin: 0 20px 0 0 !important;
}