.root {
  min-height: calc(100vh - 123px);
}

.actions {
  padding: 40px 0;
}

.headerWrapper {
  width: 100%;
  align-self: flex-start;
}
