.ui.input.error > input {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
  box-shadow: none;
}

.ui.selection.dropdown {
  min-width: auto;
  width: 100%;
}

.ui.tab.segment {
  border: none !important;
  box-shadow: none !important;
}

a {
  color: black;

  &:hover {
    text-decoration: underline;
  }
}
.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.DayPicker-Months {
  flex-wrap: nowrap;
}

#chartdiv {
  width: 100%;
  height: 500px;
}
