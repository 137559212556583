.root {
  height: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0 20px 20px 20px;
  overflow: auto;
  position: relative;
}

.inputsContainer {
  width: 100%;
  padding-top: 20px;
}

.globalError {
  width: 100%;
  align-self: flex-end;
}

.submitsContainer {
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  padding: 20px 0;
}
