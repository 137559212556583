.root {
  width: 100%;
  height: 100%;
}

.controls {
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #d4d4d5;
  position: relative;
}

.switchers {
  display: flex;
  justify-content: space-between;
  width: 230px;
}

.count {
  position: absolute;
  left: 0;
  padding: 20px;
}

.zoom {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 0;
  padding: 20px;
}

.photoWrapper {
  overflow: auto;
  width: 100%;
  height: calc(100% - 102px);
}

.img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  visibility: hidden;
  transform-origin: 0 0;
  transform: scale(2);
  user-select: none;

  &.active {
    visibility: visible;
  }
}

.loader {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
