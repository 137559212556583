.root {
  position: relative;
  width: 500px;
}

.submitContainer {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
