.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 150px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  margin: 20px 0;
}

.dragAccept {
  border-color: #00e676;
}

.dragReject {
  border-color: #ff1744;
}